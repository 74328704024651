//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PictureResponsive from '~/components/PictureResponsive.vue';

export default {
  name: 'ProductModal',
  components: {
    PictureResponsive,
  },
  props: {
    modalName: {
      type: String,
      default: '',
    },
    imagePath: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
